<template>

  <div class="drop-like-its-hot">

    <div class="p-6 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle"
             class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file"
             accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />

      <label for="assetsFieldHandle" class="block cursor-pointer">
        <span>
          {{t('Explain to our users they can drop files in here or')}} <span class="underline">{{t('click here')}}</span>{{t(' to upload their files')}}
        </span>
      </label>
<!--      <ul class="mt-4" v-if="this.filelist.length" v-cloak>-->
<!--        <li class="text-sm p-1" v-for="(file, index) in filelist" :key="`i-${index}`">-->
<!--          {{ file.name }}<button class="ml-2" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">remove</button>-->
<!--        </li>-->
<!--      </ul>-->
    </div>

  </div>

</template>
<script>
export default {
  name: 'DropFile',
  props: {

  },
  data() {
    return {
      filelist: [], // Store our uploaded files

      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    };
  },
  created() {},
  mounted() {},
  methods: {
    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.$emit('files', this.filelist);
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    }
  }
}
</script>