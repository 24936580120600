<template>
  <div class="CustomersTable">
    <import ref="ImportModal" />
    <h3 class="  mb-3">
      <span class="title">{{ t("Customers") }}</span>
<!--      <button-->
<!--        class="button is-pulled-right is-small"-->
<!--        :disabled="exporting"-->
<!--        @click="exportReport"-->
<!--      >-->
<!--        <span class="icon">-->
<!--          <span-->
<!--            :class="[-->
<!--              'fas',-->
<!--              {-->
<!--                'fa-download': !exporting,-->
<!--                'fa-spin fa-spinner': exporting,-->
<!--              },-->
<!--            ]"-->
<!--          ></span>-->
<!--        </span>-->
<!--        <span>{{ t('Export Customers') }}</span>-->
<!--      </button>-->

      <button
          class="button is-pulled-right mr-3 is-small hide-on-mobile"
          @click="
          openImportModal(
            {
              identifier: (
                this.$store.state.me.activeSeller ??
                this.$store.state.me.user.companies[0] ?? { identifier: '' }
              ).identifier,
            },
            'invoice_invoice'
          )
        "
      >
        <span class="icon">
          <span
              :class="[
              'fas',
              {
                'fa-upload': !importing,
                'fa-spin fa-spinner': importing,
              },
            ]"
          ></span>
        </span>
        <span>{{ t('Import Invoices') }}</span>
      </button>

      <button
        class="button is-pulled-right mr-3 is-small hide-on-mobile"
        @click="
          openImportModal(
            {
              identifier: (
                this.$store.state.me.activeSeller ??
                this.$store.state.me.user.companies[0] ?? { identifier: '' }
              ).identifier,
            },
            'organizational_company'
          )
        "
      >
        <span class="icon">
          <span
            :class="[
              'fas',
              {
                'fa-upload': !importing,
                'fa-spin fa-spinner': importing,
              },
            ]"
          ></span>
        </span>
        <span>{{ t('Import Customers') }}</span>
      </button>


      <div class="div is-inline-block is-pulled-right">
        <div class="control  has-icons-right is-inline-block mr-3">
          <input class="input is-pulled-right is-small"
                 v-model="searchText"
                 :disabled="loading" @keyup="triggerSearch" @keyup.enter.prevent.stop="getNewPage(1)">
          <span class="icon is-right">
            <i class="fas fa-search" v-if="!loading"></i>
            <i class="fas fa-spin fa-spinner" v-if="loading"></i>
          </span>
        </div>
      </div>


    </h3>

    <Table
      :rowClickHandler="
        (row = {}) => {
          if (!row.company_id) return true;
          $router.push({
            name: 'InvoiceCustomer',
            params: { row },
          });
        }
      "
      :loading="loading"
      @newPage="getNewPage"
      :pageData="pageData"
      :headers="headers"
      :rows="rows"
      :breakingPoint="600"
    />
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

import Table from "../../components/Dynamic/Table.vue";

import { mapActions } from "vuex";
import Import from "@/views/Settings/Modals/Import";

export default {
  name: "CustomerMobileTable",
  components: { Import, Table },
  data() {
    return {
      onlyOpenAmount: false,
      loading: false,
      exporting: false,
      pageData: {
        currentPage: 1,
        lastPage: 1,
      },
      headers: (
          this.$store.state.me.activeSeller ??
          this.$store.state.me.user.companies[0] ?? { identifier: "" }
      ).identifier === 'ICMS' ? this.$store.getters["TableHeaders/getHeaders"](
          "cm_incaze_nl_all_customers_ICMS"
      ) : this.$store.getters["TableHeaders/getHeaders"](
        "cm_incaze_nl_all_customers_mobile"
      ),
      rows: [],
      searchText: '',
      searchTimeout: null,
      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    };
  },
  watch: {
    "$store.state.me.activeSeller"() {
      this.getNewPage();
      this.rows = [];
    },
  },
  computed: {
    preprocessedFilters() {
      let filters = {};

      if(this.searchText.length > 2) {
          filters['value'] = `%${this.searchText}%`;

          // filters['owner_identifier'] = {
          //   operand: 'like',
          //   value: `%${this.searchText}%`,
          //   or: {
          //     name: {
          //       operand: 'like',
          //       value: `%${this.searchText}%`
          //     },
          //   }
          // };

      }

      return filters;
    }
  },
  mounted() {
    this.getNewPage();
    this.$toast = useToast();
    this.$store.state.emitter.$on("importCompleted", () => {
      this.getNewPage(1);
    });
  },
  methods: {
    ...mapActions([
      "dataviewKit/getViewData",
      "exportKit/postExportViewDataset",
      "dataviewKit/getCustomerData"
    ]),

    triggerSearch() {
      if(this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      this.searchTimeout = setTimeout(() => {
        this.getNewPage(
            1
        )
      }, 800);
    },
    exportReport() {
      this.exporting = true;
      this["exportKit/postExportViewDataset"](
          {
            view: (
                this.$store.state.me.activeSeller ??
                this.$store.state.me.user.companies[0] ?? { identifier: "" }
            ).identifier === 'ICMS' ? "cm_incaze_nl_all_customers_ICMS" : "cm_incaze_nl_all_customers_mobile",
            paginated: false,
            parameters: {
              owner_identifier: (
                  this.$store.state.me.activeSeller ??
                  this.$store.state.me.user.companies[0] ?? { identifier: "" }
              ).identifier,
              parent_identifier: false,
            },
            the_headers: this.headers.map((item) => {
              return item.name
            }),
        },
        { "content-type": "application/json" }
      )
        .then((data) => {
          if (data.success) {
            var a = document.createElement("a");
            a.href = data.file.url;
            a.target = "_blank";
            a.download = `${this.$route.params.id}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            this.$toast.error("Something went wrong", {});
          }
          this.exporting = false;
        })
        .catch((data) => {
          console.error(data);
          this.$toast.error(data, {});

          this.exporting = false;
        });
    },
    getNewPage(page = 1) {
      this.loading = true;
      this["dataviewKit/getCustomerData"](
        {
          view: (
              this.$store.state.me.activeSeller ??
              this.$store.state.me.user.companies[0] ?? { identifier: "" }
          ).identifier === 'ICMS' ? "cm_incaze_nl_all_customers_ICMS"
              : "cm_incaze_nl_all_customers_mobile",          page: page,
          parameters: {
            owner_identifier: (
              this.$store.state.me.activeSeller ??
              this.$store.state.me.user.companies[0] ?? { identifier: "" }
            ).identifier,
            parent_identifier: false,
          },
          filters: this.preprocessedFilters
        },
        { "content-type": "application/json" }
      )
        .then((data) => {
          this.pageData = {
            currentPage: data.current_page,
            lastPage: data.last_page,
          };
          this.rows = JSON.parse(JSON.stringify(data.data));
          this.loading = false;
        })
        .catch((data) => {
          console.error(data);
          this.$toast.error(data, {});
          this.loading = false;
        });
    },
    openImportModal(company, objectType) {
      const { identifier } = company;
      this.$refs.ImportModal.open({
        owner_identifier: identifier,
        objectType: objectType,
      });
    },
  },
};
</script>
