<template>
  <div id="RaiseDispute">
    <div class="modal" :class="{ 'is-active': isOpen }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card" :style="{
        width: '65vw',
      }">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Import {{ types[objectType] }}
            <span class="icon is-pulled-right" v-if="loaders.upload || loaders.saving">
              <span class="fas fa-spin fa-spinner"></span>
            </span>
          </p>
          <button class="delete" aria-label="close"  v-if="!(loaders.upload || loaders.saving)" @click="close"></button>
        </header>
        <section class="modal-card-body content mb-0 p-0 px-4">

          <drop-file :disabled="loaders.upload" @files="handleFiles" v-if="mapping.missing === false"/>

          <div class="columns is-multiline" v-if="mapping.missing">

            <div class="column is-12">

              <button :key="`sheet-${sheet}`" v-for="(sheet, index) in mapping.preview" @click="mapping.sheet = index">
                {{ t('Sheet') }} {{ index }}
              </button>

            </div>

            <div class="column is-12">

              <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-narrow">
                <thead>
                <tr>
                  <th :key="`i-${item}`" v-for="(item, iindex) in typeof mapping.preview[mapping.sheet][0] !== 'undefined' ? mapping.preview[mapping.sheet][0] : []">
                    <div class="field">
                      <div class="control select is-small">
                        <select class="is-small"
                                :disabled="loaders.saving"
                                @change="handleFieldDropdownSelection"
                                v-model="mapping.mapping[mapping.sheet][iindex]">
                          <option value="">Select</option>
                          <template v-for="(field, key) in mapping.fields">
                            <option :value="key"
                                    v-if="mapping.used[mapping.sheet].indexOf(key) === -1 || mapping.mapping[mapping.sheet][iindex] === key"
                                    :key="`opt-${key}`">
                              {{ field }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr :key="`row-${row}`" v-for="row in mapping.preview[mapping.sheet]">
                  <td :key="`item-${item}`" v-for="item in row">
                    <small>{{ item }}</small>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>

          <hr class="mt-0" />
        </section>
        <footer class="modal-card-foot p-0">
          <span v-if="!loaders.saving"
              class="card-footer-item has-text-danger is-clickable"
              @click="clearData(0, true)"
          >
            Cancel
          </span>
          <template v-if="mapping.missing">
                      <span
                          class="card-footer-item has-text-info is-clickable"
                          @click="saveImport"
                      >
            <span v-if="!loaders.saving">{{ t('Save mapping & import') }}</span>
            <span v-if="loaders.saving">{{ t('Saving your mapping and importing your data...') }}</span>
                        <span class="icon">
                          <span class="fa fa-spin fa-spinner" v-if="loaders.saving"></span>
                        </span>
          </span>
          </template>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom__table {
  tbody {
    tr {
      td {
        vertical-align: middle;
        input {
          width: auto;
        }
      }
    }
  }
}
</style>

<script>
import DropFile from "@/components/Forms/Input/DropFile";
import { useToast } from "vue-toastification";

export default {
  name: 'Import',
  components: {DropFile},
  data () {
    return {
      isOpen: false,
      isLoading: false,

      owner_identifier: null,
      objectType: null,
      authorizedDomainsData: [],
      authorizingDomain: '',

      loaders: {
        upload: false,
        saving: false,
      },
      types: {
        invoice_invoice: 'Customers Invoices',
        organizational_company: 'Customers',
      },
      mapping: {
        document: '',
        missing: false,
        fields: null,
        preview: null,
        mapping: {},
        sheet: null,
        previous: {},
      },
      numberOfRecordsToSkip: 0,

      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    }
  },
  computed: {
  },
  mounted() {
    this.$toast = useToast();
  },
  methods: {

    handleFieldDropdownSelection() {
      const self = this;

      self.mapping.used[self.mapping.sheet] = [];

      for(let i in self.mapping.mapping[self.mapping.sheet]) {

        if(self.mapping.mapping[self.mapping.sheet][i].length > 0) {

          self.mapping.used[self.mapping.sheet].push(self.mapping.mapping[self.mapping.sheet][i]);

        }

      }

    },
    async handleFiles(files) {
      const self = this;
      if(!self.loaders.upload) {
        self.loaders.upload = true;
        let formData = new FormData();
        let file = files[0];
        let extension = file.name.split('.'); extension = extension[extension.length - 1];
        formData.append('file', new File([file], (self.objectType + "_" + self.owner_identifier + "_" + new Date().getTime() + "." + extension ) , {
          type: file.type,
          lastModified: file.lastModified,
        }));
        formData.append('type', self.objectType);
        formData.append('owner_identifier', self.owner_identifier);
        // formData.append("force_mapping", true);
        formData.append('numberOfRecordsToSkip', self.numberOfRecordsToSkip);
        formData.append('numberOfRecordsToPreview', 25);


        try {
          let data = await self.$store.dispatch("importKit/postUploadImportFile", formData, {
            'Content-Type': 'multipart/form-data',
          });

          if (!data.success) {
            throw data;
          }


          if(typeof data.mapping !== 'undefined' &&
                   !data.mapping) {

            if(!this.isOpen) { this.isOpen = true; }

            self.mapping = {
              missing: true,
              document: data.document,
              fields: data.fields,
              preview: data.preview,
              mapping: {},
              sheet: typeof data.preview[0] !== 'undefined' ? 0 : null,
              used: {},
            };

            for(let sheet in data.preview) {
              self.mapping.mapping[sheet] = {};
              self.mapping.used[sheet] = [];
              let prev = {};
              if(typeof data.previous[sheet] !== 'undefined') {
                Object.keys(data.previous[sheet]).forEach(function(key) {
                  prev[data.previous[sheet][key]] = key;
                });
              }
              if(typeof data.preview[sheet][0] !== 'undefined') {
                for (let row in data.preview[sheet][0]) {
                  let value = '';
                  if(typeof prev[row] !== 'undefined') {
                    value = prev[row];
                  }
                  self.mapping.mapping[sheet][row] = value;
                }
              }
            }
            console.log(self.mapping);
          } else {
            this.$store.state.emitter.$emit('importCompleted');
            this.$toast.success(
                `Your import was scheduled. You'll receive a confirmation email when your data is processed.`
            );
            this.close();
            // self.successState();
          }
          self.loaders.upload = false;

        } catch (error) {
          console.log(error);
          self.loaders.upload = false;
        }



      }


    },
    open(values) {
      console.log(values);
      this.objectType = values.objectType;
      this.owner_identifier = values.owner_identifier;

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    clearData(time = 5000, close = false) {
      const self = this;
      setTimeout(function() {
        self.mapping = {
          document: '',
          missing: false,
          fields: null,
          preview: null,
          mapping: {},
          sheet: null,
        };
      }, time);
      if(close) { this.close(); }
    },
    async saveImport() {
      const {
        owner_identifier
      } = this;
      if(this.loaders.saving) { return false; }
      try {
        this.loaders.saving = true;
        let data = await this.$store.dispatch("importKit/postSaveMappingImport", {
          owner_company: owner_identifier,
          owner_identifier: owner_identifier,
          document: this.mapping.document,
          mapping: this.mapping.mapping
        });
        this.$toast.success(
            `Your import was scheduled. You'll receive a confirmation email when your data is processed.`
        );
        this.$store.state.emitter.$emit('importCompleted');
        this.loaders.saving = false;
        if (!data.success) {
          throw data;
        }

        this.close();

      } catch (error) {
        this.loaders.saving = false;
        console.log(error);
      }
    },
  },
};
</script>
