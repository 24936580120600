<template>
  <div id="Customers">
    <template v-if="isMobile">

    <TableMobile />
    </template>
    <template v-else>

      <Table />
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import Table from "./Table.vue";
import TableMobile from "@/views/Customers/TableMobile.vue";

export default {
  components: { Table, TableMobile },
  data() {
    return {
      isMobile: true,
    }
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 700 && (
          this.$store.state.me.user.email === 'info@marcocorver.nl'
          || this.$store.state.me.user.email === 'maurice@consultinvest.nl'
          || this.$store.state.me.user.email === 'jim.harders@gmail.com'
      );
    },
  },

  async mounted() {
    this.checkMobile();
  }
}
</script>
